import { getDownloadURL } from "firebase/storage";
import { getStorage, ref, listAll } from "firebase/storage";
import { useState, useEffect } from "react"
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

export default function PhotoGallery() {

    const [photos, setPhotos] = useState([]);

    const fetchPhotos = async () => {
        const storage = getStorage();
        const folderRef = ref(storage, "photoGallery");  // Reference to the main folder

        try {
            // List all files and subfolders in the main folder
            const result = await listAll(folderRef);

            // Create an empty map to store the result
            const subfolderMap = new Map();

            // Iterate over each subfolder (result.prefixes)
            const filePromises = result.prefixes.map(async (item) => {
                const subfolderRef = ref(storage, item.fullPath);
                const subfolderName = subfolderRef.name;

                // List all files inside the subfolder
                const resultSub = await listAll(subfolderRef);

                if (resultSub.items.length > 0) {
                    const cover = resultSub.items[0];

                    const downloadURL = await getDownloadURL(cover);
                    subfolderMap.set(subfolderName, downloadURL); // Key: subfolder name, Value: array of URLs
                }

                return null;
            });

            // Wait for all subfolders' files to be processed
            await Promise.all(filePromises);

            setPhotos(subfolderMap);

        } catch (error) {
            console.error("Error fetching files from Firebase Storage:", error);
        }
    };


    useEffect(() => {
        fetchPhotos();
    }, []);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [actualGallery, setActualGallery] = useState([]);
    const [actualPhoto, setActualPhoto] = useState("");
    const [actualPhotoIndex, setActualPhotoIndex] = useState(0);

    const openGallery = async (subfolder) => {
        const storage = getStorage();
        const folderRef = ref(storage, "photoGallery");  // Reference to the main folder

        try {
            // List all files and subfolders in the main folder
            const result = await listAll(folderRef);

            // Create an empty map to store the result
            const galleryMap = [];

            var desiredGallery;

            result.prefixes.map((item) => {
                if (item.name === subfolder) {
                    desiredGallery = item;
                }
            })

            const subfolderRef = ref(storage, desiredGallery.fullPath);
            const subfolderName = subfolderRef.name;

            // List all files inside the subfolder
            const resultSub = await listAll(subfolderRef);

            const subfilePromises = resultSub.items.map(async (subItem) => {
                const downloadURL = await getDownloadURL(subItem);
                galleryMap.push(downloadURL);
            });

            // Wait for all subfolders' files to be processed
            await Promise.all(subfilePromises);

            // Return or set the map as needed
            setActualGallery(galleryMap);
            setActualPhoto(galleryMap[0]);
            setActualPhotoIndex(0);

        } catch (error) {
            console.error("Error fetching files from Firebase Storage:", error);
        }

        setTitle(subfolder);
        setOpen(true);
    }

    const switchPhotoLeft = () => {
        if (actualPhotoIndex > 0) {
            const indexChange = actualPhotoIndex - 1;
            setActualPhotoIndex(indexChange);
            setActualPhoto(actualGallery[indexChange]);
        }
    }

    const switchPhotoRight = () => {
        if (actualPhotoIndex < actualGallery.length - 1) {
            const indexChange = actualPhotoIndex + 1;
            setActualPhotoIndex(indexChange);
            setActualPhoto(actualGallery[indexChange]);
        }
    }

    return (
        <div className="bg-white dark:bg-slate-800">

            <Dialog open={open} onClose={setOpen} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen h-screen overflow-y-auto flex justify-center items-center">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform max-h-[calc(100vh-4rem)] rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white dark:bg-slate-700 w-full h-full flex flex-col justify-between">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white mt-4">
                                            {title}
                                        </DialogTitle>
                                        <div className="flex-grow flex justify-center items-center p-4">
                                            <img className="w-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" src={actualPhoto} alt="Zdjęcie z galerii"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 dark:bg-slate-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={switchPhotoRight}
                                    className="inline-flex w-full justify-center rounded-md bg-green-500 dark:bg-cyan-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                >
                                    &gt;&gt;&gt;
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={switchPhotoLeft}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    &lt;&lt;&lt;
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Galeria</h2>
                    <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        {Array.from(photos.entries()).map(([subfolder, url]) => (
                            <div key={subfolder} className="group relative mb-6">
                                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                                    <img
                                        src={url}
                                        onClick={() => openGallery(subfolder)}
                                        className="max-h-[calc(100vh-4rem)] w-full object-cover object-center"
                                        alt="Miniaturka zdjęcia z galerii"
                                    />
                                </div>
                                <p className="text-base font-semibold text-gray-900 dark:text-white">{subfolder}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="mb-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <p className="text-lg leading-8 text-gray-600 dark:text-white">Źródła zdjęć, Juwenalia Krakoskie 2024:</p>
                <div>
                    <a href="https://cowkrakowie.pl/2024/05/zorganizowano-multimedialne-widowisko-w-krakowie-zdjecia" className="text-lg underline leading-8 text-gray-600 dark:text-white">https://cowkrakowie.pl/2024/05/zorganizowano-multimedialne-widowisko-w-krakowie-zdjecia</a>
                </div>
                    <a href="https://albumy.agh.edu.pl/index.php?/category/757" className="text-lg underline leading-8 text-gray-600 dark:text-white">https://albumy.agh.edu.pl/index.php?/category/757</a>
            </div>
        </div >
    )
}