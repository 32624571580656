import { db } from '../config/FirebaseConfig';
import { getDownloadURL } from "firebase/storage";
import { getDocs, collection } from 'firebase/firestore';
import { getStorage, ref } from "firebase/storage";
import { useState, useEffect } from "react"

import maps from "../assets/maps.png"
import { useLogo } from "../components/Layout";

export default function Contact() {

  const { facebookType, instagramType, emailType } = useLogo();

  const [management, setManagement] = useState([]);

  const fetchManagementPositions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "teamManagement"));
      const managementPositions = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const managementWithImages = await Promise.all(managementPositions.map(async (item) => {
        try {
          const storage = getStorage();
          const url = await getDownloadURL(ref(storage, "team/" + item.image));
          return { ...item, image: url };  // Return the item with the image URL
        } catch (error) {
          console.error("Error fetching image URL:", error);
          return { ...item, image: null };  // Still return the item, but with a null image URL in case of error
        }
      }));

      const sortedData = managementWithImages.sort((a, b) => a.id - b.id);

      setManagement(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching management positions:", error);
    }
  };

  useEffect(() => {
    fetchManagementPositions();
  }, []);

  return (<>
    <div className="bg-white dark:bg-slate-800 card">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Kontakt</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
            Skontaktuj się z nami!
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {management.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img alt="Zdjęcie członka zarządu" src={person.image} className="h-16 w-16 rounded-full" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">{person.role}</p>
                  <h3 className="text-base leading-7 tracking-tight text-gray-900 dark:text-white">{person.email}</h3>
                  <h3 className="text-base leading-7 tracking-tight text-gray-900 dark:text-white">{person.phoneNumber}</h3>
                </div>
              </div>
            </li>
          ))}
          <li>
            <div className="flex items-center gap-x-6">
              <img alt="Adres mailowy" src={emailType} className="h-16 w-16 rounded-full" />
              <div>
                <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">Adres mailowy</h3>
                <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">sknmospwr@gmail.com</p>
              </div>
            </div>
          </li>

          <li>
            <div className="flex items-center gap-x-6">
              <a href="https://www.facebook.com/MOSPWr"><img alt="Facebook SKN MOS" src={facebookType} className="h-16 w-16 rounded-full" /></a>
              <div>
                <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">Facebook</h3>
                <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">SKN MOS</p>
              </div>
            </div>
          </li>

          <li>
            <div className="flex items-center gap-x-6">
              <a href="https://www.facebook.com/projektPIWO/"><img alt="Facebook P.I.W.O. Light Show" src={facebookType} className="h-16 w-16 rounded-full" /></a>
              <div>
                <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">Facebook</h3>
                <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">Projekt P.I.W.O. Light Show</p>
              </div>
            </div>
          </li>

          <li>
            <div className="flex items-center gap-x-6">
              <a href="https://www.instagram.com/skn.mos/"><img alt="Instagram" src={instagramType} className="h-16 w-16" /></a>
              <div>
                <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">Instagram</h3>
                <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">skn.mos</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div className="card bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Siedziba</p>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">Łukasza Górnickiego 22, 50-337 Wrocław</p>
        </div>
      </div>
      <div className="flex justify-center m-5">
        <img className="piwo-image w-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" src={maps} alt='Lokalizacja SKN MOS'></img>
      </div>
    </div>

  </>)
}