import couch1 from "../assets/couch-1.jpg"
import couch2 from "../assets/couch-2.jpg"
import couch3 from "../assets/couch-3.jpg"
import couch4 from "../assets/couch-4.jpg"

export default function CrazyCouch() {
    return (<>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="">
                <br />
                <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Szalona Kanapa</p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Pomysł ten zrodził się dość spontanicznie w oczekiwaniu na Dni Aktywności Studenckiej w 2015r. na których prezentowaliśmy również makietę projektu P.I.W.O, gdzie można było zagrać w Snake’a czy Tetris’a. Konstrukcja pojazdu zajęła nam 3 dni i noce.</p>
                <ul id="pros">
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Zaczęliśmy od docięcia i przyspawania metalowego podwozia</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Kolejnym krokiem było zamontowanie kół napędowych (przednich), skrętnych oraz łańcuchów</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Silniki z których korzystamy pochodzą ze skuterów elektrycznych, a ich łączna moc wynosi prawie 3KM</li>
                </ul>
            </div>
            <div className="flex justify-center m-5">
                <img className="w-auto mr-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" src={couch1} alt="Team MOS razem z kanapą"></img>
            </div>
        </div>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="">
                <br />
                <ul id="pros">
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Do zasilenia silników używamy akumulatorów samochodowych, które trzymamy w bagażniku. Takie rozwiązanie pozwala nam na przejechanie prawie 4 km</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Sterowanie kanapy zostało zaprogramowane na procesorze serii STM32</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Kanapę kontrolujemy za pomocą pada od PlayStation 2</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Nasi programiści dali nam możliwość poczucia wiatru we włosach, gdyż w trybie Turbo kanapa potrafi osiągnąć prędkość 15 km/h</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Kanapa posiada oryginalną obudowę na elektronikę, a także awangardową, czerwoną piankę, która opiewa metalową konstrukcję pełniąc funkcję zderzaka</li>
                </ul>

                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Projekt ten spotkał się z ogólną aprobatą wśród społeczności studenckiej i nie tylko. Przejechaliśmy na niej pochód juwenaliowy w 2015 roku i wierzymy, że będzie jeszcze z nami na niejednym evencie.</p>

            </div>

            <div className="flex justify-center m-5">
                <img className="w-auto mr-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" src={couch2} alt="Zdjęcie przedstawiające kanapę"></img>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-center m-5 mx-auto w-full max-w-screen-xl">
                <img className="w-full max-w-full mb-4 md:mb-0 md:mr-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 md:w-1/2 md:-ml-4 lg:-ml-0" src={couch3} alt="Kanapa przed budynkiem C-13"></img>
                <img className="w-full max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 md:w-1/2 md:-ml-4 lg:-ml-0" src={couch4} alt="Kanapa przy budynku D-21"></img>
            </div>
        </div>
    </>)
}