import { db } from '../config/FirebaseConfig';
import { getDownloadURL } from "firebase/storage";
import { getDocs, collection } from 'firebase/firestore';
import { getStorage, ref } from "firebase/storage";
import { useState, useEffect } from "react"

import '../css/Recruitment.css'
import '../index.css'

export default function Recruitment() {

  const [recruitment, setRecruitment] = useState([]);

  const fetchRecruitmentPositions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "recruitment"));
      const recruitment = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const recruitmentWithImages = await Promise.all(recruitment.map(async (item) => {
        try {
          const storage = getStorage();
          const url = await getDownloadURL(ref(storage, "recruitment/" + item.image));
          return { ...item, image: url };  // Return the item with the image URL
        } catch (error) {
          console.error("Error fetching image URL:", error);
          return { ...item, image: null };  // Still return the item, but with a null image URL in case of error
        }
      }));

      const sortedData = recruitmentWithImages.sort((a, b) => a.id - b.id);

      setRecruitment(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching recruitment positions:", error);
    }
  };

  useEffect(() => {
    fetchRecruitmentPositions();
  }, []);

  return (<>
    <h1 className="info mt-14 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Dołącz do nas już dziś - Rekrutacja 24/7!</h1>
    {recruitment.map((position) => (
      <div key={position.id} className="overflow-hidden bg-white dark:bg-slate-800 card flex justify-center items-center">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 justify-items-center">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl text-center lg:text-left">{position.name}</p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white text-center lg:text-left">{position.description}</p>
              </div>
            </div>
            <div id="poster-div">
              <img src={position.image} alt="Zdjęcie działu" className="recruitment-image w-auto max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
            </div>
          </div>
        </div>
      </div>
    ))};
    <div className="apply">
      <a className="apply-button dark:text-white" href="https://docs.google.com/forms/d/e/1FAIpQLSdaK6sCPo95v3AujeRuVhP2NitghYYn6c83QTrsGUNeuiARLA/viewform">Aplikuj</a>
    </div>
  </>)
}