import laser from "../assets/projektor_laserowy.jpg"
import moduly1 from "../assets/moduly.jpg"
import moduly2 from "../assets/moduly-2.jpg"
import moduly3 from "../assets/moduly-3.jpg"
import moduly4 from "../assets/moduly-4.jpg"
import piwo1 from "../assets/piwo-1.jpg"
import piwo2 from "../assets/piwo-2.jpg"
import piwo3 from "../assets/piwo-3.jpg"
import piwo4 from "../assets/piwo-7.jpg"
import piwo5 from "../assets/piwo-5.jpg"
import piwo6 from "../assets/piwo-4.jpg"
import piwo7 from "../assets/piwo-8.jpg"

import { useLogo } from "../components/Layout"

export default function PIWO() {

  const { piwoType } = useLogo();

  return (<>
    <div className="overflow-hidden bg-white dark:bg-slate-800 card">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">P.I.W.O. Light Show</p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Projekt P.I.W.O. – czyli Potężny Indeksowany Wyświetlacz Oknowy, stworzony przez Nas nietypowy system oświetlenia budynku. Dzięki niemu jesteśmy w stanie zamienić akademik (bądź inny budynek) w ogromny wyświetlacz. Myślimy, że poniższe zdjęcia rozwieją wszelkie wątpliwości:</p>
            </div>
          </div>
          <div id="poster-div">
            <img src={piwoType} alt="Logo projektu P.I.W.O." className="piwo-image w-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
          </div>
        </div>
      </div>
    </div>

    <div className="flex justify-center">
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
          <div className="h-64 w-auto overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
            <img
              alt="Zdjęcie nr 1 z pokazu P.I.W.O."
              src={piwo1}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="h-64 w-auto overflow-hidden rounded-lg">
            <img
              alt="Zdjęcie nr 2 z pokazu P.I.W.O."
              src={piwo2}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>
        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
          <div className="h-64 w-auto overflow-hidden rounded-lg">
            <img
              alt="Zdjęcie nr 3 z pokazu P.I.W.O."
              src={piwo3}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="h-64 w-auto overflow-hidden rounded-lg">
            <img
              alt="Zdjęcie nr 4 z pokazu P.I.W.O."
              src={piwo4}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="h-64 w-auto overflow-hidden rounded-lg">
            <img
              alt="Zdjęcie nr 5 z pokazu P.I.W.O."
              src={piwo5}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>
        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
          <div className="h-64 w-auto overflow-hidden rounded-lg">
            <img
              alt="Zdjęcie nr 6 z pokazu P.I.W.O."
              src={piwo6}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="h-64 w-auto overflow-hidden rounded-lg">
            <img
              alt="Zdjęcie nr 7 z pokazu P.I.W.O."
              src={piwo7}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>
      </div>
    </div>

    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Sprzęt oraz oprogramowanie</p>
      <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">System oświetleniowy wykorzystywany w Projekcie P.I.W.O. jest naszym autorskim rozwiązaniem. Cały sprzęt został przez nas zaprojektowany i wykonany. Jego najważniejszą częścią są moduły oświetleniowe, które na czas pokazu są umieszczane w pokojach akademika. Cała sieć jest sterowana z poziomu komputera.</p>
    </div>

    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Moduły oświetleniowe</p>
      <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Moduł możemy podzielić na 3 sekcje: sekcja zasilania, sekcja komunikacji radiowej oraz sekcja oświetlenia. Sekcja komunikacji została oparta na gotowym module radiowym, działającym na częstotliwości 2,4 GHz. Sekcja oświetlenia to łącznie 6 diod power LED. Po dwie diody koloru czerwonego, zielonego i niebieskiego. Dzięki temu jesteśmy w stanie wygenerować 16,777,216 kolorów (2^24) unikalnych kolorów. Sekcja zasilania to połączenie zewnętrznych zasilaczy komputerowych i wewnętrznych układów zasilania tj. przetwornic impulsowych typu Step-down.</p>
    </div>

    <div className="overflow-hidden bg-white dark:bg-slate-800 card">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Projektor laserowy</p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Niesamowite wrażenia wizualne podczas pokazu zapewnią nie tylko moduły ledowe, ale również nasz projektor laserowy. Dowiesz się o nim więcej z naszego osobnego artykułu dostępnego poniżej.</p>
              <div className="mt-4">
                <a href="/laser" className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Laser</a>
              </div>
            </div>
          </div>
          <img src={laser} alt="Laser" className="piwo-image w-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
        </div>
      </div>
    </div>

    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Historia</p>
      <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Wszystko zaczęło się, kiedy to jeden ze studentów Politechniki Wrocławskiej zauważył, że światło wydobywające się z okien akademika ułożyło pewien charakterystyczny/rozpoznawalny kształt – tak narodziła się idea, aby podczas każdych następnych juwenaliów, zamieniać akademik w wielki wyświetlacz i prezentować na nim „piwne animacje”.</p>
    </div>

    <div className="card overflow-hidden bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">1. Pierwsze edycje projektu (lata 2007-2008)</p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Na samym początku operowaliśmy na 2 żarówkach i kilometrach przewodów. Zwykła żarówka oraz żarówka pokryta czerwoną farbą, pozwalały nam tworzyć dwukolorowe animacje. Żarówki były umieszczane w pokojach, a przewody ze wszystkich pokoi, łączyły się w jednym miejscu na każdym piętrze, gdzie następie, w odpowiednim czasie miało miejsce włączanie i wyłączanie żarówek.</p>
            </div>
          </div>
          <div id="poster-div">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/krqvysB6bH0?si=zNbgKQjxc7-0LSrE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>

    <div className="card overflow-hidden bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">2. Upgrade, aż 4 kolory! (lata 2009-2010)</p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Po pewnym czasie przyszedł czas na ulepszenia. Osiem żarówek o różnych kolorach(niebieski, czerwony, zielony i żółty), polepszyły wrażenia wizualne pokazów. Dzięki bogatszej palecie kolorów oraz efektowi regulacji jasności świecenia żarówek, mogliśmy tworzyć bardziej szczegółowe animacje i tym samym powiększyć pulę wykorzystywanych w animacjach tematów.</p>
            </div>
          </div>
          <div id="poster-div">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/FKqmq_sQKtM?si=KMxxLPLj--Xxy4Mg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>

    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">3. Moduły piwne (lata 2011-2024)</p>
      <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">W roku 2011 miał miejsce największy postęp wizualny oraz technologiczny. Własnoręcznie stworzyliśmy moduły ledowe, które rozszerzyły pulę kolorów do ponad 16 milionów. Zamieniliśmy kilometry miedzi na łączność bezprzewodową. Zamiast niewydajnych żarówek, zastosowaliśmy diody LED i wszystko to umieściliśmy w bezpiecznej obudowie.</p>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
        <img
          alt="Zdjęcie nr 1 prezentujące moduły piwne"
          src={moduly1}
          className="rounded-lg bg-gray-100"
        />
        <img
          alt="Zdjęcie nr 2 prezentujące moduły piwne"
          src={moduly2}
          className="rounded-lg bg-gray-100"
        />
        <img
          alt="Zdjęcie nr 3 prezentujące moduły piwne"
          src={moduly3}
          className="rounded-lg bg-gray-100"
        />
        <img
          alt="Zdjęcie nr 4 prezentujące moduły piwne"
          src={moduly4}
          className="rounded-lg bg-gray-100"
        />
      </div>
    </div>
  </>)
}