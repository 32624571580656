import { db } from '../config/FirebaseConfig';
import { getDocs, collection } from 'firebase/firestore';
import { useState, useEffect } from "react"

import { useLogo } from '../components/Layout';

export default function FormerMembers() {

  const [formerMembers, setFormerMembers] = useState([]);

  const { formerType } = useLogo();

  const fetchFormerMembers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "formerMembers"));
      const formerMembers = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const sortedData = formerMembers.sort((a, b) => a.id - b.id);

      setFormerMembers(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching distinguished people:", error);
    }
  };

  useEffect(() => {
    fetchFormerMembers();
  }, []);

  const FullNameColumns = ({ formerMembers }) => {
    return (
      <div className="container mx-auto p-4">
        {/* Grid layout with 4 equal columns */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {formerMembers.map((member, index) => (
            <div key={index} className="p-4 border border-gray-300 rounded-lg shadow-lg">
              <h2 className="text-sm font-medium leading-6 text-gray-900 dark:text-white">{member.kind}</h2>
              <ul>
                {member.names.map((person, idx) => (
                  <li key={idx} className="mb-2 text-gray-700 dark:text-gray-400">
                    {person}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (<>
    <div className='p-12'>
      <div className='flex justify-center'>
        <img src={formerType} alt='Zegar cofający czas'></img>
      </div>
      <div className="flex justify-center px-4 sm:px-0">
        <h1 className="text-base font-semibold leading-7 text-green-500 dark:text-cyan-400">Byli członkowie SKN MOS</h1>
      </div>
    </div>
    <FullNameColumns formerMembers={formerMembers} />
  </>)
}