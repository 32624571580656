import "../css/Recruitment.css"
import { useLogo } from "../components/Layout"

export default function Download() {

    const { downloadType } = useLogo();

    return (<>
        <div className="apply mt-10">
            <img src={downloadType}></img>
            <a className="apply-button dark:text-white" href="https://drive.google.com/file/d/1W0gIoTLTfYNGqJAFVFQG3EZf91pb-dcp/view?usp=sharing">Pobierz edytor animacji P.I.W.O.</a>
        </div>
    </>)
}