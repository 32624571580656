import { db } from '../config/FirebaseConfig';
import { getDocs, collection } from 'firebase/firestore';
import { useState, useEffect } from "react"

import "../css/QandA.css"

export default function QAndA() {

  const [QandA, setQandA] = useState([]);

  const fetchQandA = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "QandA"));
      const QandA = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const sortedData = QandA.sort((a, b) => a.id - b.id);

      setQandA(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching Q&A:", error);
    }
  };

  useEffect(() => {
    fetchQandA();
  }, []);

  return (<>
    <ul role="list" className="divide-y divide-gray-100 max-w-4xl mx-auto my-14 p-12">
      <div id="content">
        <p className="mt-2 mb-4 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Q&A</p>
      </div>
      {QandA.map((item) => (
        <li key={item.id} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400">{item.question}</p>
              <p className="mt-1 text-xs leading-5 text-gray-500 dark:text-white">{item.answer}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </>)
}