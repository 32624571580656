import bbc1 from "../assets/bbc-1.jpg"
import bbc2 from "../assets/bbc-2.jpg"

export default function BigBinaryClock() {
    return (<>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="">
                <br />
                <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Big Binary Clock</p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Klasyczne zegary binarne, które zdobyły ostatnio dość dużą popularność w formie zabawek i gadżetów, wyświetlają czas w formacie BCD – 4 bity kodują każdą pojedynczą cyfrę składającą się na zapis czasu.</p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Big Binary Clock:</p>
                <ul id="pros">
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">to czasomierz prezentujący bieżącą godzinę w zapisie zero-jedynkowym</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">używa 18 świetlnych okręgów umieszczonych w oknach</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">kolor zielony reprezentuje godzinę</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">kolor niebieski reprezentuje minuty</li>
                    <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">kolor czerwony reprezentuje sekundy</li>
                </ul>
            </div>
            <div className="flex justify-center m-5">
                <img className="w-auto mr-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" src={bbc1} alt="Big Binary Clock"></img>
            </div>
        </div>

        <div className="overflow-hidden bg-white dark:bg-slate-800 card">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                Poszczególnym oknom (bitom) przypisane są kolejne potęgi dwójki. W celu odczytania tak zapisanej liczby należy zsumować liczby przypisane świecącym oknom.</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                Przykład: Sekundy (kolor czerwony) to: 1 + 2 + 4 + 16 = 23.</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                Zegar działa od 13 listopada 2009 roku.</p>
                        </div>
                    </div>
                    <div className="image-div">
                        <img
                            src={bbc2}
                            alt="Zdjęcie opisujące działanie BBC"
                            className="piwo-image w-auto mb-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>)
}